import React, { useEffect, useState } from "react";
import "./melInterface.css";

interface ChatInterfaceProps {
  onExit?: () => void; // Make onExit optional
}

const ChatInterface: React.FC<ChatInterfaceProps> = ({ onExit }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    // Check if container is available in the DOM
    const checkContainerExistence = setInterval(() => {
      const container = document.getElementById("humbird-assistant-wrapper");

      // Dynamically load external scripts when container exists
      if (container) {
        const script = document.createElement("script");
        script.src = "./ketsal-app.min.js";
        script.async = true;
        script.onload = () => {
          setScriptLoaded(true);
        };
        
        // Append script to document body
        document.body.appendChild(script); 

        // Stop interval check as container is found
        clearInterval(checkContainerExistence);
      }
    }, 100);

    // Clean up interval on component unmount
    return () => {
      clearInterval(checkContainerExistence);
    };
  }, []);

    return (
      <div style={{ position: "relative" }}>
        <div 
          style={{ 
            position: "absolute", 
            top: 0, 
            left: 0, 
            width: "100%", 
            height: "100%", 
            backgroundColor: "#FF7A00", // Change this to your preferred background color
            zIndex: -1 
          }} 
        ></div>

        <div id="humbird-assistant-wrapper"></div>
      </div>
    );

};

export default ChatInterface;
