import { useEffect, useState } from "react";
import React from "react";
import "./App.css";
import MainApp from "./pages/Account";
import AuthPage from "./pages/AuthPage";
import { useAuth } from "./hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, setUser } from "./store/Account/AccountStore";
import { getFranchise, setFranchise, getCredentialValidation, getAllFranchises } from "./store/Franchise/FranchiseStore";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { fetchWhiteLabel, selectWhiteLabel, selectIsWhiteLabelLoaded } from "./store/WhiteLabel/WhiteLabelStore";
import { getCurrentDomain, getGlobalStyles, assetImage } from "./utility/whiteLabelManager";
import ChatInterface from "./components/chat/melInterface"; // Import chat component
import { Backdrop } from "@mui/material";

const MOBILE_WIDTH = 768; // Define breakpoint for mobile

const App = () => {
  const { currentUser, user } = useAuth();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH);

  useEffect(() => {
    currentUser();
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(fetchUser());
      dispatch(getFranchise());
      dispatch(getCredentialValidation());
      dispatch(getAllFranchises());
    } else {
      dispatch(setUser(null));
      dispatch(setFranchise(null));
    }
  }, [user]);

  useEffect(() => {
    const initWhiteLabel = async () => {
      const domainName = await getCurrentDomain();
      dispatch(fetchWhiteLabel(domainName));
    };
    initWhiteLabel();
  }, []);

  const whiteLabel = useSelector(selectWhiteLabel);
  const isWhiteLabelLoaded = useSelector(selectIsWhiteLabelLoaded);
  const GlobalStyles = getGlobalStyles(whiteLabel);

  useEffect(() => {
    document.title = whiteLabel.metaTitle;
    const favicon = document.getElementById("favicon");
    if (favicon !== null && whiteLabel.faviconPath !== "") {
      favicon.setAttribute("href", assetImage(whiteLabel.faviconPath));
    }
  }, [whiteLabel]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_WIDTH);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <GlobalStyles />
      <Backdrop
        sx={{ backgroundColor: "black", opacity: 1, color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        transitionDuration={{ appear: 0, enter: 0, exit: 1000 }}
        open={!isWhiteLabelLoaded}
      ></Backdrop>

      <Router>
        {isMobile ? (
          <ChatInterface /> // Render chat interface on mobile
        ) : (
          <Routes>
            <Route path="*" element={user ? <MainApp /> : <AuthPage />} />
          </Routes>
        )}
      </Router>
    </>
  );
};

export default App;